import { createReducer } from '@reduxjs/toolkit';
import { productLoaded, canBeCombinedWithOtherProductsInCartLoaded, updateHasBlockedCombinedProductButton } from './action';
import initialProductState from './state';

const productReducer = createReducer(initialProductState, (builder) => {
    builder.addCase(canBeCombinedWithOtherProductsInCartLoaded, (state, { payload }) => (
        {
            ...state,
            strongAlcoholCheck: {
                ...state.strongAlcoholCheck,
                canBeCombinedWithOtherProductsInCart: payload,
                canBeCombinedWithOtherProductsInCartRequestLoaded: true,
            },
        }
    ));
    builder.addCase(productLoaded, (state, { payload }) => (
        {
            ...state,
            ...payload,
            strongAlcoholCheck: {
                ...state.strongAlcoholCheck,
                ...payload.strongAlcoholCheck,
                canBeCombinedWithOtherProductsInCart: state.strongAlcoholCheck.canBeCombinedWithOtherProductsInCart,
            },
        }
    ));

    builder.addCase(updateHasBlockedCombinedProductButton, (state, { payload }) => (
        {
            ...state,
            hasBlockedCombinedProductButton: payload,
        }
    ));
});

export default productReducer;
