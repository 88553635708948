import { createRoot, hydrateRoot } from 'react-dom/client';
import { document, window } from '../../js/globals';
import ProductPage from '../../js/legacy/productpage';
import recentlyViewed from '../../js/productPage/recentlyViewed';
import $ from '../../js/util/fancybox-jquery';
import ProductInformation from './Components/ProductInformation/ProductInformation';
import store from './Store/instance';

const container = document?.getElementById('product-page-ssr');
const fallbackContainer = document?.getElementById('product-page-ssr-fallback');

const now = new Date();

if (window && document) {
    if (container) {
        hydrateRoot(
            container, (
                <ProductInformation
                    store={store}
                    now={now}
                    locale={store.getState().meta.locale}
                    webshopContextData={window.siteMetadata}
                />
            ),
        );
    } else if (fallbackContainer) {
        const root = createRoot(fallbackContainer);
        root.render(<ProductInformation
            store={store}
            now={now}
            locale={store.getState().meta.locale}
            webshopContextData={window.siteMetadata}
        />);
    }

    const event = new CustomEvent('product-page-loaded');
    document?.dispatchEvent(event);
}

$(() => {
    ProductPage.init();

    const productId = document?.getElementById('productpage')?.dataset?.productId;
    if (productId) {
        recentlyViewed(parseInt(productId, 10));
    }

    delete window?.PRODUCT_INFORMATION_STATE;
});
