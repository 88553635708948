import { window } from '../../../../js/globals';

interface EventProperties {
    cartEdit: boolean;
    category: string;
    editorType: string;
    label: string;
    productId: number;
    variantId: number;
}

export class Tracker {
    private readonly editorType = 'SVG Editor';

    private readonly googleAnalyticsEventCategory = 'svg-editor';

    public editorOpened(productId: number, variantId: number, isCartEdit: boolean): void {
        window?.analytics.track(
            'Editor Opened',
            this.getEventProperties(
                productId,
                variantId,
                isCartEdit,
            ),
        );
    }

    public personalisationConfirmed(productId: number, variantId: number, isCartEdit: boolean): void {
        window?.analytics.track(
            'Personalisation Confirmed',
            this.getEventProperties(
                productId,
                variantId,
                isCartEdit,
            ),
        );
    }

    private getEventProperties(productId: number, variantId: number, isCartEdit: boolean): EventProperties {
        return {
            cartEdit: isCartEdit,
            category: this.googleAnalyticsEventCategory,
            editorType: this.editorType,
            label: String(productId),
            productId,
            variantId,
        };
    }
}
