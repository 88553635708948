import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import type { Store } from '../../Store/store';
import InformationBlock from '../InformationBlock/InformationBlock';
import type { WebshopContextData } from '../../../general/WebshopContext/WebshopContextProvider';
import WebshopContextProvider from '../../../general/WebshopContext/WebshopContextProvider';
import Section from '../../../general/Section/Section';
import Usps from '../InformationBlock/Usps/Usps';
import Specifications from '../Specifications/Specifications';
import SpecificationsLink from '../Specifications/SpecificationsLink';
import RelatedProducts from '../RelatedProducts/RelatedProducts';
import TranslationProvider from '../../../general/Translation/TranslationProvider';
import { productPageLoaded } from '../../Store/UI/action';
import ImageGalleryBlock from '../GalleryBlock/ImageGalleryBlock';
import ABTestProductHeading from '../InformationBlock/ABTestProductHeading/ABTestProductHeading';

export interface ProductInformationProps {
    locale: string;
    now: Date;
    store: Store;
    webshopContextData: WebshopContextData;
}

const ProductInformation: React.FC<ProductInformationProps> = ({
    locale,
    store,
    webshopContextData,
}) => {
    store.dispatch(productPageLoaded());

    return <React.StrictMode>
        <WebshopContextProvider data={webshopContextData}>
            <TranslationProvider dictionary={store.getState().translations}>
                <IntlProvider locale={locale}>
                    <ReduxProvider store={store}>
                        <Section noPadding>
                            <div className="product-information">
                                <ABTestProductHeading />
                                <ImageGalleryBlock />
                                <InformationBlock />
                            </div>
                        </Section>
                        <SpecificationsLink />
                        <RelatedProducts />
                        <Section>
                            <Usps />
                        </Section>
                        <Specifications />
                    </ReduxProvider>
                </IntlProvider>
            </TranslationProvider>
        </WebshopContextProvider>
    </React.StrictMode>;
};
export default ProductInformation;
