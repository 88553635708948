import classNames from 'classnames';
import type { MutableRefObject } from 'react';
import React from 'react';
import useEditorType from '../../../Hooks/UseEditorType';
import { PrimaryCtaButton } from '../../../Store/UI/state';
import ButtonStack from '../../../../general/Button/ButtonStack';
import { window } from '../../../../../js/globals';
import Translation from '../../../../general/Translation/Translation';
import { useTypedSelector } from '../../../Store/connectors';

interface Props {
    isCartEdit: boolean;
    onClick: () => void;
}

const StartPersonalisingButton = React.forwardRef<HTMLElement, Props>(({ isCartEdit, onClick }, buttonRef) => {
    const { isQuickEditorProduct, isSvgEditorProduct } = useEditorType();

    const primaryCtaButton = useTypedSelector(({ ui }) => ui.primaryCtaButton);
    const editorUrl = useTypedSelector(({ product }) => product.ctaButton.editorUrl);
    const isCombinedArticle = useTypedSelector((state) => state.combinedArticle !== null);
    const isPersonalised = useTypedSelector(({ personalisation }) => personalisation.isPersonalised);
    const isOutOfStock = useTypedSelector(({ shipping }) => shipping.isOutOfStock);
    const canBeCombinedWithOtherProductsInCart = useTypedSelector(({ product }) => product.strongAlcoholCheck.canBeCombinedWithOtherProductsInCart);
    const hasBlockedCombinedProductButton = useTypedSelector(({ product }) => product.hasBlockedCombinedProductButton);

    const onStartPersonalising = (e: React.MouseEvent) => {
        if (!canBeCombinedWithOtherProductsInCart || isOutOfStock) {
            e.preventDefault();
            return;
        }

        if (isQuickEditorProduct) {
            e.preventDefault();
        }

        if (isSvgEditorProduct) {
            e.preventDefault();
        }

        onClick();

        window?.analytics?.track('Element Clicked', {
            category: 'Product page',
            label: isPersonalised || isCartEdit ? 'Edit Personalisation' : 'Start Personalisation',
        });
    };

    return (
        <ButtonStack isXs isFluid>
            <a
                ref={buttonRef as MutableRefObject<HTMLAnchorElement>}
                rel="nofollow"
                href={editorUrl}
                className={classNames('button button--large js-startPersonalisation', {
                    'button--ghost': primaryCtaButton !== PrimaryCtaButton.StartPersonalising,
                    'button--happy-path': primaryCtaButton === PrimaryCtaButton.StartPersonalising,
                    disabled: !canBeCombinedWithOtherProductsInCart || isOutOfStock || hasBlockedCombinedProductButton,
                    startCombinedPersonalisation: isCombinedArticle,
                })}
                onClick={onStartPersonalising}
            >
                <span>
                    {isPersonalised || isCartEdit
                        ? <Translation pageString="GiftEditorWidget" stringId="edit_personalisation"/>
                        // If you dare to fix the translation typo, then also change it in backoffice
                        : <Translation pageString="GiftEditorWidget" stringId="personaize_button"/>}
                </span>
            </a>
        </ButtonStack>
    );
});
export default StartPersonalisingButton;
