import type React from 'react';
import { useContext } from 'react';
import classNames from 'classnames';
import { useTypedSelector } from '../../../Store/connectors';
import Rating from '../Rating/Rating';
import styles from './ABTestProductHeading.module.scss';
import { pdpHeadingPlacement } from '../../../../../js/optimizely/fx/flags';
import { WebshopContext } from '../../../../general/WebshopContext/WebshopContextProvider';
import { useIsMobile } from '../../../../general/hooks/useSize';
import { useIsInitialRequestMobile } from '../../../../general/WebshopContext/WebshopContext';

const ABTestProductHeading: React.FC = () => {
    const productSubtitle = useTypedSelector(({ product }) => product.productSubtitle);
    const productTitle = useTypedSelector(({ product }) => product.productTitle);
    const isVariationB = useContext(WebshopContext).experimentVariation === pdpHeadingPlacement.variationB;
    const isMobileOrTablet = useIsMobile(useIsInitialRequestMobile());

    if (!isMobileOrTablet || !isVariationB) {
        return null;
    }

    return <div className="product-information-desktop">
        <h1 className="h3">{productTitle}</h1>
        {productSubtitle && <h2 className={classNames([styles.ProductHeading_title__subtitle as string], 'mt-2', 'mb-2')}>{productSubtitle}</h2>}
        <div className="mb-4">
            <Rating/>
        </div>
    </div>;
};

export default ABTestProductHeading;
