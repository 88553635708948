import { Device, getDeviceInfo } from '../../Util/Device';
import { editorTypeSet } from '../MetaData/action';
import { EditorType, Version } from '../MetaData/state';
import type { TypedMiddleware } from '../store';
import { productPageLoaded } from '../UI/action';
import { updateEditorType } from '../Product/action';

export const editorTypeMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    if (productPageLoaded.match(action) || updateEditorType.match(action)) {
        const { cart, meta, product } = storeApi.getState();

        switch (true) {
            case !product.isPersonalizable:
                storeApi.dispatch(editorTypeSet(null));
                break;
            case product.svgEditorActive && (meta.svgEditorAbTestVersion === Version.B || cart.isSvgCartEdit):
                storeApi.dispatch(editorTypeSet(EditorType.SVG_EDITOR));
                break;
            case !product.quickEditorActive:
                storeApi.dispatch(editorTypeSet(EditorType.ADVANCED_EDITOR));
                break;
            case cart.isCartEdit:
                storeApi.dispatch(editorTypeSet(EditorType.QUICK_EDITOR));
                break;
            case getDeviceInfo().device === Device.MOBILE:
                storeApi.dispatch(editorTypeSet(EditorType.QUICK_EDITOR));
                break;
            default:
                storeApi.dispatch(editorTypeSet(EditorType.ADVANCED_EDITOR));
                break;
        }
    }

    next(action);
};
