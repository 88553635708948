import { createReducer } from '@reduxjs/toolkit';
import { cartEditPersonalisationLoaded, finishedPersonalising } from './action';
import { initialPersonalisationState } from './state';

export const personalisationReducer = createReducer(initialPersonalisationState, (builder) => {
    builder.addCase(cartEditPersonalisationLoaded, (state, { payload }) => ({
        ...state,
        isPersonalised: payload.isPersonalised,
        yimp: payload.yimp,
    }));

    builder.addCase(finishedPersonalising, (state, { payload }) => ({
        ...state,
        isPersonalised: payload.isPersonalised,
        yimp: payload.yimp,
    }));
});
