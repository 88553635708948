import { createAction } from '@reduxjs/toolkit';
import type { ProductState } from './state';

export enum ProductActionType {
    CAN_BE_COMBINED_WITH_OTHER_PRODUCTS_IN_CART_LOADED = 'CAN_BE_COMBINED_WITH_OTHER_PRODUCTS_IN_CART_LOADED',
    PRODUCT_LOADED = 'PRODUCT_LOADED',
    PRODUCT_SWITCHED = 'PRODUCT_SWITCHED',
    UPDATE_EDITOR_TYPE = 'UPDATE_EDITOR_TYPE',
    UPDATE_HAS_BLOCKED_COMBINED_PRODUCT_BUTTON = 'UPDATE_HAS_BLOCKED_COMBINED_PRODUCT_BUTTON',
}

export const canBeCombinedWithOtherProductsInCartLoaded = createAction<boolean, ProductActionType>(ProductActionType.CAN_BE_COMBINED_WITH_OTHER_PRODUCTS_IN_CART_LOADED);
export const productLoaded = createAction<ProductState, ProductActionType>(ProductActionType.PRODUCT_LOADED);
export const productSwitched = createAction<void, ProductActionType>(ProductActionType.PRODUCT_SWITCHED);
export const updateHasBlockedCombinedProductButton = createAction<boolean, ProductActionType>(ProductActionType.UPDATE_HAS_BLOCKED_COMBINED_PRODUCT_BUTTON);
export const updateEditorType = createAction<void, ProductActionType>(ProductActionType.UPDATE_EDITOR_TYPE);
