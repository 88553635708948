type FlagAbTest = {
    [variation: string]: string;
    flagKey: string;
    variationA: string;
    variationB: string;
};

export const pdpHeadingPlacement: FlagAbTest = {
    flagKey: 'b4-pdp-heading-placement',
    variationA: 'below_image',
    variationB: 'above_image',
};

export const plpValentinePage: FlagAbTest = {
    flagKey: 'b4-plp-valentines-layout',
    variationA: 'default',
    variationB: 'inspiration_without_banner',
    variationC: 'inspiration_with_banner',
};
