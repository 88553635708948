import debounce from 'debounce';
import { document, window } from '../globals';
import { isBreakpointLG } from '../util/sizes';

const stickyHeaderObserver = (): void => {
    const triggerElement = document?.querySelector('[data-sticky-trigger]');

    if (!triggerElement) {
        return;
    }

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                document?.body.classList.toggle('has-sticky', entry.intersectionRatio < 1);
            });
        },
        { threshold: [1] },
    );

    if (!isBreakpointLG()) {
        observer.observe(triggerElement);
    }

    window?.addEventListener('resize', debounce(() => {
        if (!isBreakpointLG()) {
            document?.body.classList.add('has-sticky');
            observer.observe(triggerElement);
        } else {
            observer.unobserve(triggerElement);
            document?.body.classList.remove('has-sticky');
        }
    }, 1000));
};

const semiStickyHeader = () => {
    document?.body.classList.add('cro-semi-sticky');

    let lastScrollPosition = window?.scrollY || 0;
    const threshold = 50;

    const showHide = debounce(() => {
        if (!window) {
            return;
        }

        const isScrollingUp = window.scrollY < lastScrollPosition - threshold;
        const isScrollingDown = window.scrollY > lastScrollPosition + threshold;

        if (isScrollingUp) {
            document?.body.classList.add('cro-semi-sticky--show');
            document?.body.classList.remove('cro-semi-sticky--hide');
        } else if (isScrollingDown) {
            document?.body.classList.add('cro-semi-sticky--hide');
            document?.body.classList.remove('cro-semi-sticky--show');
        }

        if (isScrollingUp || isScrollingDown) {
            lastScrollPosition = window.scrollY;
        }
    }, 10);

    if (isBreakpointLG()) {
        window?.removeEventListener('scroll', showHide);
        document?.body.classList.add('cro-semi-sticky--show');
    } else {
        window?.addEventListener('scroll', showHide);
    }

    window?.addEventListener('resize', debounce(() => {
        if (isBreakpointLG()) {
            document?.body.classList.remove('cro-semi-sticky');
            window?.removeEventListener('scroll', showHide);
        } else {
            document?.body.classList.add('cro-semi-sticky');
            window?.addEventListener('scroll', showHide);
        }
    }, 1000));
};

const initOptimizelyHeaderFunctions = () => {
    if (window) {
        window.optimizelyFunctions = {
            semiStickyHeader,
        };
    }
};

export {
    initOptimizelyHeaderFunctions,
    semiStickyHeader,
    stickyHeaderObserver,
};
